.home-teacher-page {
    background-color: white;
    padding: 70px 150px;
}

.home-teacher-page-group-1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.home-teacher-page-group-1-label {
    color: rgba(0, 0, 0, 1);
    font-size: 25px;
    font-weight: 600;
}

.home-teacher-page-group-1-btn {
    width: 201px;
    height: 41px;

    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;


    color: rgba(255, 255, 255, 1);
    font-size: 15px;
    font-weight: 700;
}

.home-teacher-page-group-2 {
    padding-top: 30px;
}

.home-teacher-page-group-2-main {
    padding: 32px 80px;
    background-color: rgba(250, 250, 250, 1);
}

.home-teacher-page-2-1 {
    margin: 0px 28px;
    display: flex;
}

.home-teacher-page-2-1-img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    position: relative;
    object-fit: cover;
}

.home-teacher-page-2-1-logo-frame {
    background-color: rgba(255, 255, 255, 1);
    border-radius: 28px;
    box-shadow: 0px 3px 6px #00000029;
    width: 56px;
    height: 56px;
    position: absolute;
    object-fit: cover;
    margin-top: -10px;
    margin-left: -25px;
}

.home-teacher-page-group-2-2 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 29px;
}

.home-teacher-page-group-2-2-label-1 {
    color: rgba(0, 140, 119, 1);
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 9px;
}

.home-teacher-page-group-2-2-label-2 {
    color: rgba(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 600;
}

.home-teacher-page-group-2-2-label-3 {
    color: rgba(203, 203, 203, 1);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 11px;
}

.home-teacher-page-group-2-2-label-4 {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 600;
}

.home-teacher-page-group-2-3 {
    display: flex;
    flex-direction: column;
    padding-top: 93px;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.home-teacher-page-group-2-3-label-1 {
    color: rgba(203, 203, 203, 1);
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 11px;
}

.home-teacher-page-group-2-3-label-2 {
    color: rgba(0, 0, 0, 1);
    font-size: 14px;
    font-weight: 600;
}

.home-teacher-page-group-2-4 {
    display: flex;
    flex-direction: column;
    gap: 62px;
    float: right;
}

.home-teacher-page-group-2-4-label-1 {
    color: rgba(0, 140, 119, 1);
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}

.home-teacher-page-group-2-4-label-2 {
    color: rgba(203, 203, 203, 1);
    font-size: 15px;
    font-weight: 600;
}

.home-teacher-page-group-2-4-label-3-container {
    background-color: rgba(234, 255, 251, 1);
    border-radius: 5px;
}

.home-teacher-page-group-2-4-label-3 {
    color: rgba(0, 140, 119, 1);
    font-size: 15px;
    font-weight: 600;
    text-align: center;
}

@media (max-width: 992px) {
    .home-teacher-page {
        padding: 30px 10px;
    }
}

@media (max-width: 600px) {
    .home-teacher-page-group-1 {
        justify-content: center;
        gap: 10%;
    }
}

@media (max-width: 600px) {
    .home-teacher-page-group-1-label {
        color: rgba(0, 0, 0, 1);
        font-size: 20px;
        font-weight: 600;
        width: 45%;
    }
}

@media (max-width: 600px) {
    .home-teacher-page-group-1-btn {
        width: 45%;
        font-size: 13px;
        font-weight: 600;
    }
}

@media (max-width: 992px) {
    .home-teacher-page-group-2-main {
        padding: 32px 10px;
    }
}

@media (max-width: 992px) {
    .home-teacher-page-group-2-2-label-1 {
        margin-top: 15px;
    }
}

@media (max-width: 992px) {
    .home-teacher-page-group-2-3 {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        justify-content: flex-start;
        align-items: flex-start;
        align-content: flex-start;
    }
}


@media (max-width: 992px) {
    .home-teacher-page-group-2-4 {
        display: flex;
        flex-direction: column;
        gap: 20px;
        float: left;
    }
}

@media (max-width: 992px) {
    .home-teacher-page-2-1 {
        margin: 0px 10px;
    }
}

@media (max-width: 992px) {
    .home-teacher-page-group-2-2-label-3 {
        margin-bottom: 5px;
    }
}

@media (max-width: 992px) {
    .home-teacher-page-group-2-3-label-1 {
        margin-bottom: 5px;
    }
}

.row>* {
    padding-right: unset !important;
    padding-left: unset !important;
    margin-top: unset !important;
}

.saved-jobs {
    margin-top: 70px;
}

.saved-jobs-btn {
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 15px;

    width: 150px;
    height: 40px;
}

.suggested-for-you {
    margin-top: 70px;
}

.apply-now-btn {
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 15px;

    width: 171px;
    height: 41px;

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}