/*Gawish*/
[direction="rtl"] .bm-burger-button {
    right: 36px;
    left: auto;
}

[direction="rtl"] .page_title {
    margin-left: 0px;
    margin-right: 62px;
}

[direction="rtl"] .shapreter-row>div {
    border-right: unset;
    border-left: 1px solid #eee;
}

[direction="rtl"] .shapreter-row>div:last-child {
    border-left: 0;
}

/*------------------------------------------*/

/*Tariq*/
[direction="rtl"] .me-1 {
    margin-left: 0.25rem;
}

[direction="rtl"] .application {
    margin-right: 2rem;
    margin-left: unset;
}

[direction="rtl"] .header-left .search-area {
    /* width: 18.875rem;
    border-radius: 2.375rem; */
    margin-right: 5rem;
    margin-left: unset;
}

[direction="rtl"] .header-left .search-area .form-control {
    border-bottom-right-radius: 2.625rem !important;
    border-top-right-radius: 2.625rem !important;
}

[direction="rtl"] .dlabnav .header-info2 i {
    margin-right: 1rem;
}

[direction="rtl"][data-sidebar-style="full"][data-layout="vertical"] .dlabnav .metismenu>li .has-arrow:after {
    left: 1.5rem;
}

[direction="rtl"] .dlabnav .metismenu .has-arrow:after {
    border-right: 0.3125rem solid #C8C8C8;
    border-left: 0.3125rem solid transparent;
    bottom: 48%;
    left: 1.875rem;
}

[direction="rtl"] .dlabnav .header-info2 img {
    margin-left: 1.2rem;
}

[direction="rtl"] .me-sm-5 {
    margin-left: 3rem !important;
    margin-right: unset !important;
}

[direction="rtl"] .me-3 {
    margin-left: 1rem;
    margin-right: unset !important;
}

[direction="rtl"] .ms-3 {
    margin-right: 1rem;
}

[direction="rtl"] .new_job_title {
    text-align: right;
}

[direction="rtl"] .separate-row>div:nth-child(odd) {
    border-left: 1px solid #eee;
    border-right: 0px;
}

[direction="rtl"] .input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-right: -1px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 1rem;
    border-top-left-radius: 1rem;
}

[direction="rtl"] .input-group:not(.has-validation)> :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-bottom-right-radius: 1rem;
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

[direction="rtl"] .header-left .search-area .input-group-text {
    border-top-left-radius: 2.625rem !important;
    border-bottom-left-radius: 2.625rem !important;
}

[direction="rtl"] .shapreter-row>div {
    border-left: 1px solid #eee;
    border-right: 0px;
}

[direction="rtl"] .ms-2 {
    margin-right: 0.5rem;
}

[direction="rtl"] .search-job .search-dropdown:after {
    position: relative;
}

[direction="rtl"] .table.dataTable.no-footer {
    border-bottom: 0;
    margin-right: 0;
}

[direction="rtl"] .listline-wrapper .item i {
    padding-left: 8px;
    padding-right: 0px;
}

[direction="rtl"] .listline-wrapper .item:not(:last-child) {
    border-left: 1px solid #EEEEEE;
    border-right: 0px;

    margin-left: 5px;
    margin-right: 0px;

    padding-top: 2px;
    padding-left: 15px;

    padding-right: 0px
}

[direction="rtl"] .float-end {
    float: left !important;
}

[direction="rtl"] .text-start {
    text-align: right !important;
}

[direction="rtl"] .me-auto {
    margin-left: auto;
    margin-right: 0 !important;
}

[direction="rtl"] .me-2 {
    margin-left: 0.5rem;
    margin-right: 0rem !important;
}

[direction="rtl"] .Studios-info {
    margin-left: 1rem;
    margin-right: 0rem;
}

[direction="rtl"] .ms-auto {
    margin-right: auto;
    margin-left: 0 !important;
}

[direction="rtl"] .ms-1 {
    margin-right: 0.25rem;
}

[direction="rtl"] .ms-sm-4 {
    margin-right: 1.5rem;
}

[direction="rtl"] .profile-pic img {
    margin-left: 2rem;
}

[direction="rtl"] .profile-back .social-btn {
    left: 2rem;
    right: unset;
}

[direction="rtl"] .profile-back .social-btn .btn {
    margin-right: 1rem;
}

[direction="rtl"] .me-4 {
    margin-left: 1.5rem;
    margin-right: unset;
}

[direction="rtl"] .ms-4 {
    margin-right: 1.5rem;
}

[direction="rtl"] .modal-header .btn-close {
    padding: 0.5rem 0.5rem;
    margin: -0.5rem auto -0.5rem -0.5rem;
}

[direction="rtl"] .btn-margin {
    margin-left: 15px;
}

[direction="rtl"] .attends .inner {
    text-align: right;
}

[direction="rtl"] .resource-second-line {
    margin-right: 157px;
    margin-left: unset;
}

@media only screen and (max-width: 47.9375rem) {
    [direction="rtl"] .resource-second-line {
        margin-right: unset !important;
    }
}

[direction="rtl"] .child-protection {
    text-align: right;
}

[direction="rtl"] .col-xs-1 {
    padding-right: 0px;
}

[direction="rtl"] .child-protection-paragraph {
    margin-right: 10px;
    margin-left: unset;
}

[direction="rtl"] .col-xs-3 {
    padding-left: 0;
}

[direction="rtl"] .diversity {
    text-align: right;
}

[direction="rtl"] .diversity-heading {
    text-align: right;
}

[direction="rtl"] .c-modal-close-button {
    right: unset;
    left: 16px;
}

[direction="rtl"] .c-modal-header-subtitle-bold {
    margin-right: unset;
    margin-left: 5px;
}

[direction="rtl"] .c-modal-header-subtitle-normal {
    margin-right: unset;
    margin-left: 5px;
}

[direction="rtl"] .profile-modal-body-section-1-resume-buttons {
    margin-right: unset;
    margin-left: 5px;
}

[direction="rtl"] .profile-modal-body-section-1-stats-info img {
    margin-left: 8px;
}

[direction="rtl"] .profile-modal-footer-applicants:nth-child(1) {
    text-align: right;
}

[direction="rtl"] .profile-modal-footer-applicants {
    padding: 18px 6px 0px 0px;
}

[direction="rtl"] .profile-modal-footer-applicants img {
    margin-right: 5px;
    margin-left: unset;
}

[direction="rtl"] .profile-modal-body-container {
    margin: 18px 13px 26px 32px;
}

[direction="rtl"] .profile-modal-body-section-2 {
    margin: 18px 13px 26px 23px
}

[direction="rtl"] .accordion-card {
  padding-left: unset;
  padding-right: 12px;
}