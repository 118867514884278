/* step 1  */
.add-job-step-1 {
    padding-top: 44px;
}

.add-job-step-1-steps {
    padding: 0px 94px;
}

.add-job-step-1-main {
    padding: 40px 70px;
    margin-top: 46px;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000015;
    border-radius: 32px;
    width: 100%;
}

/* .add-job-step-1-main-edit {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000015;
    border-radius: 32px;
    width: 100%;
    height: 100%;
} */

.add-job-step-1-container {
    padding: 45px 0px 52px 0px;
}

.add-job-step-1-title {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 20px;
}

.add-job-step-1-general-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10%;
}

.add-job-step-1-general-main-edit {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.add-job-step-1-general-item {
    width: 45%;
}

.add-job-step-1-general-item-edit {
    width: 100%;
}

.add-job-step-1-label {
    color: rgba(82, 82, 82, 1);
    font-weight: 600;
    font-size: 15px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.add-job-step-1-input-field {
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding-left: 10px;
}

/* .add-job-step-1-input-field-edit {
    width: 300px;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding-left: 10px;
} */

.add-job-step-1-location {
    padding-top: 30px;
}

.add-job-step-1-monthly-salary-main {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 10%;
    padding-top: 30px;
}

.add-job-step-1-monthly-salary-main-edit {
    display: flex;
    flex-direction: column;
    align-items: unset;
    padding-top: 30px;
    width: 100%;

}

.add-job-step-1-monthly-salary-input-field {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    width: 123px;
    height: 50px;
    padding-left: 10px;
}

.add-job-step-1-monthly-salary-input-field-edit {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    width: 180px;
    height: 50px;
    padding-left: 10px;
}

.add-job-step-1-monthly-salary-title {
    padding-right: 563px;
}


.add-job-step-1-contract-length-title {
    padding-right: 372px;
}

.add-job-step-1-contract-length-title-edit {
    padding-top: 30px;
}

.add-job-step-1-monthly-salary-min-max {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
}

.add-job-step-1-monthly-salary-min-max-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}

.add-job-step-1-salary-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.add-job-step-1-monthly-salary-check-box-field {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    width: 22px;
    height: 22px;
    margin-left: 20px;
    margin-right: 18px;
}

.add-job-step-1-monthly-salary-label {
    color: rgba(82, 82, 82, 1);
    font-weight: 600;
    font-size: 15px;
}

.add-job-step-1-contract-length-years-month {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 80px;
}

.add-job-step-1-contract-length-years-month-edit {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 50px;
}

.add-job-step-1-contract-length-input-field {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    width: 181px;
    height: 50px;
    padding-left: 10px;
}

.add-job-step-1-date-input-field {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid !important;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1) !important;
    width: 246px;
    height: 50px;
    padding-left: 10px !important;
}

.add-job-step-1-date-input-field-1 {
    margin-bottom: 30px !important;
}

.add-job-step-1-description {
    padding-top: 30px;
}

.add-job-step-1-description-text-area {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding: 10px;
}

.add-job-step-1-description-text-area-edit {
    width: 95%;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding: 10px;
    height: 400px;
}

.add-job-step-1-next-back-btn {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.add-job-step-1-next-btn {
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    font-weight: 600;
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;
    width: 382px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px
}

.add-job-step-1-back-btn {
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
}

.add-job-step-1-date-edit {
    padding-top: 30px;
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-steps {
        padding: 0px 10px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-salary-info {
        display: unset;
        flex-direction: unset;
        align-items: unset;
    }
}


@media only screen and (max-width: 992px) {
    .add-job-step-1-main {
        margin: 5% 0% !important;
        width: 100% !important;
        height: 100% !important;
        padding: 20px 10px;
    }

    .add-job-step-1-general-item {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-container {
        padding: 30px 0px 52px 10px !important;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-general-main {
        flex-direction: column !important;
        gap: 0px !important;
        align-items: unset !important;
    }

    .add-job-step-1-general-main-edit {
        flex-direction: column;
        gap: 0px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-input-field {
        width: 95% !important;
    }

    .add-job-step-1-input-field-edit {
        width: 95%;
    }

}

@media only screen and (max-width: 992px) {
    .add-job-step-1-next-btn {
        font-size: 15px !important;
        font-weight: 500 !important;
        width: 80% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px !important;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-description-text-area {
        width: 95% !important;
        height: 350px !important;
    }

    .add-job-step-1-description-text-area-edit {
        width: 95%;
        height: 350px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-monthly-salary-main {
        display: flex;
        flex-direction: column !important;
        align-items: unset !important;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-monthly-salary-title {
        padding-right: unset !important;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-monthly-salary-min-max {
        align-items: unset !important;
        flex-direction: column !important;
        gap: 0px;
    }

    .add-job-step-1-monthly-salary-min-max-edit {
        flex-direction: column;
        align-items: unset;
        gap: 0px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-contract-length-title {
        padding-right: unset !important;
        padding-top: 30px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-contract-length-years-month {
        display: flex;
        flex-direction: column;
        align-items: unset;
        gap: 0px;
    }

    .add-job-step-1-contract-length-years-month-edit {
        flex-direction: column;
        align-items: unset;
        gap: 0px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-contract-length-input-field {
        width: 95%;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-date {
        padding-top: 30px;
    }

    .add-job-step-1-date-edit {
        padding-top: 0px;
    }
}


@media only screen and (max-width: 992px) {
    .add-job-step-1-label {
        padding-top: 10px;
        padding-bottom: 10px
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-date-input-field {
        width: 95%;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-monthly-salary-input-field {
        width: 95%;
        padding-left: 10px;
    }

    .add-job-step-1-monthly-salary-input-field-edit {
        width: 95%;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-monthly-salary-check-box-field {
        margin-left: unset !important;
        margin-top: 20px;
        margin-right: 10% !important;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-1-next-back-btn {
        margin-top: 70px;
    }
}

/* step 2 */
.add-job-step-2 {
    padding-top: 44px;
}

.add-job-step-2-steps {
    padding: 0px 242px;
}

.add-job-step-2-main {
    padding: 40px 70px;
    margin-top: 46px;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000015;
    border-radius: 32px;
    width: 100%;
}

.add-job-step-2-title {
    font-weight: 600;
    font-size: 20px;
    color: rgba(0, 0, 0, 1);
    padding-bottom: 10px;
}

.add-job-step-2-citizenship-match {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    gap: 10px;
}

.add-job-step-2-label {
    font-weight: 600;
    font-size: 15px;
    color: rgba(82, 82, 82, 1);
    padding-bottom: 10px;
}

.add-job-step-2-input-field {
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding-left: 10px;
    margin-bottom: 30px;
}

.add-job-step-2-candidates-education {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10%;
    width: 100%;
}

.add-job-step-2-citizenship-required {
    width: 50%;
}

.add-job-step-2-candidates {
    width: 45%;
}

.add-job-step-2-educations {
    width: 45%;
}

.add-job-step-2-certification {
    display: flex;
    align-items: center;
    align-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    /* gap: 30px; */
    gap: 20px
}

.add-job-step-2-certification-check-box {
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    width: 22px;
    height: 22px;
}

.add-job-step-2-certification-label {
    font-weight: 600;
    font-size: 13px;
    color: rgba(82, 82, 82, 1);
}

.add-job-step-2-citizenship-title {
    padding-top: 30px;
}

.add-job-step-2-text-area {
    width: 100%;
    height: 471px;
    padding: 10px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-color: rgba(112, 112, 112, 1);
    border-radius: 6px;
}


.add-job-step-2-next-back-btn {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.add-job-step-2-back-btn {
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
}

.add-job-step-2-next-btn {
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;
    width: 382px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-steps {
        padding: 0px 10px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-main {
        padding: 40px 10px;
        width: 100%;
        margin-bottom: 50px;
        height: unset;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-candidates-education {
        flex-direction: column;
        align-items: unset;
        gap: 30px;
    }

    .add-job-step-2-candidates {
        width: 100%;
    }

    .add-job-step-2-educations {
        width: 100%;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-label {
        font-weight: 600;
        font-size: 15px;
        color: rgba(82, 82, 82, 1);
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-input-field {
        width: 95%;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-text-area {
        width: 95%;
        height: unset;
        padding: 10px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-next-back-btn {
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 30px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-next-btn {
        width: 65%;
        font-size: 13px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-title {
        font-size: 20px;
        padding-bottom: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-back-btn {
        font-size: 17px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-2-certification-label {
        font-size: 10px;
    }
}

/* Step 3  */
.add-job-step-3 {
    padding-top: 46px;
}

.add-job-step-3-steps {
    padding: 0px 242px;
}

.add-job-step-3-main {
    padding: 40px 70px;
    margin-top: 46px;
    margin-bottom: 50px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000015;
    border-radius: 32px;
    width: 100%;
}

.add-job-step-3-title {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 20px;
}

.add-job-step-3-label {
    padding-top: 10px;
    font-weight: 600;
    font-size: 15px;
    color: rgba(82, 82, 82, 1);
}

.add-job-step-3-input-field {
    width: 100%;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding-left: 10px;
    margin-top: 10px;
}

.add-job-step-3-title-job-benefits {
    padding-top: 25px;
}

.add-job-step-3-text-area {
    width: 100%;
    height: 471px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding: 10px;
    margin-top: 10px;
}

.add-job-step-3-next-back-btn {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
}

.add-job-step-3-back-btn {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.add-job-step-3-next-btn {
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);

    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    width: 382px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-steps {
        padding: 0px 10px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-main {
        padding: 40px 10px;
        width: 100%;
        height: unset;
        margin-bottom: 30px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-title {
        font-size: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-label {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-input-field {
        width: 95%;
        padding-left: 10px;
        margin-top: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-title-job-benefits {
        padding-top: 20px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-text-area {
        width: 95%;
        height: unset;
        padding: 10px;
        margin-top: 25px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-next-back-btn {
        margin-top: 50px;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-back-btn {
        font-size: 17px;
        cursor: pointer;
    }
}

@media only screen and (max-width: 992px) {
    .add-job-step-3-next-btn {
        font-size: 13px;
        width: 95%;
    }
}

/* step 4  */
.add-job-step-4 {
    padding: 44px 242px;
}

.add-job-step-4-main {
    margin-top: 46px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000015;
    border-radius: 32px;
    width: 100%;
    padding: 40px 70px
}

.add-job-step-4-title {
    color: rgba(0, 0, 0, 1);
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
}

.add-job-step-4-input-field-vacancy {
    width: 94px;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-color: rgba(112, 112, 112, 1);
    border-radius: 8px;
    text-align: center;

    color: rgba(82, 82, 82, 1);
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 10px;
}

.add-job-step-4-label {
    color: rgba(82, 82, 82, 1);
    font-weight: 600;
    font-size: 15px;
}

.add-job-step-4-label-switch-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 26px;
}

.add-job-step-4-display-video-label {
    padding-top: 30px;
}

.add-job-step-4-input-field {
    /* width: 997px; */
    width: 100%;
    height: 50px;
    color: rgba(82, 82, 82, 1);
    font-weight: 400;
    font-size: 15px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-color: rgba(112, 112, 112, 1);
    border-radius: 8px;
    padding-left: 10px;
}

.add-job-step-4-text-area {
    width: 100%;
    height: 302px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-color: rgba(112, 112, 112, 1);
    border-radius: 6px;
    padding: 10px;
}

.add-job-step-4-terms-conditions {
    display: flex;
    flex-direction: row;
    padding-top: 27px;
    gap: 20px;
    align-items: center;
}

.add-job-step-4-check-box-field {
    border: 1px solid;
    border-color: rgba(112, 112, 112, 1);
    border-radius: 5px;
    width: 22px;
    height: 22px;
}

.add-job-step-4-terms-conditions-label {
    color: #6a6a6a;
    font-weight: 600;
    font-size: 15px;
}

.add-job-step-4-terms-conditions-span {
    font-size: 20px;
    font-weight: 600px;
    color: rgba(19, 140, 119, 1);
}

.add-job-step-4-next-back-btn {
    display: flex;
    flex-direction: column;
    gap: 38px;
    align-items: center;
    padding-top: 35px;
}

.add-job-step-4-back-btn {
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-size: 20px;
    cursor: pointer;
}

.add-job-step-4-next-btn {
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;
    width: 300px;
    height: 49px;
}

.add-job-step-4-app-link-label-input-field {
    padding-top: 30px;
}

.add-job-step-4-app-link-input-field {
    margin-top: 10px;
}

@media only screen and (max-width: 1100px) {
    .add-job-step-4 {
        padding: 44px 5%;
    }
}

@media only screen and (max-width: 1100px) {
    .add-job-step-4-main {
        margin-top: 46px;
        width: 100%;
        height: unset;
        padding: 40px 5%
    }
}

@media only screen and (max-width: 1100px) {
    .add-job-step-4-title {
        font-size: 15px;
        padding-bottom: 15px;
    }
}

@media only screen and (max-width: 1100px) {
    .add-job-step-4-input-field-vacancy {
        margin-bottom: 15px;
    }
}


@media only screen and (max-width: 1100px) {
    .add-job-step-4-input-field {
        width: 95%;
    }
}

@media only screen and (max-width: 1100px) {
    .add-job-step-4-text-area {
        width: 95%;
        height: unset;
    }

    .add-job-step-4-terms-conditions {
        padding-top: 20px;
        gap: 20px;
    }

    .add-job-step-4-terms-conditions-span {
        font-size: 20px;
    }

    .add-job-step-4-back-btn {
        color: rgba(0, 0, 0, 1);
        font-weight: 600;
        font-size: 20px;
        cursor: pointer;
    }

    .add-job-step-4-next-btn {
        width: 85%;
    }

    .add-job-step-4-label {
        font-size: 12px;
    }

    .add-job-step-4-terms-conditions-label {
        font-size: 12px;
    }

    .add-job-step-4-terms-conditions-span {
        font-size: 18px;
        font-weight: 600px;
        color: rgba(19, 140, 119, 1);
    }
}

input[type='checkbox'] {
    accent-color: rgba(19, 140, 119, 1);
}


.switch {
    position: relative;
    display: inline-block;
    width: 58px;
    height: 27px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -webkit-transition: .4s;
    transition: .4s;
    background-color: white;
    border: 1px solid rgba(112, 112, 112, 1);
}

.slider:before {
    position: absolute;
    content: "";
    height: 25px;
    width: 25px;
    background-color: #008c77;
    -webkit-transition: .4s;
    transition: .4s;
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(31px);
}


/* Rounded sliders */
.slider.round {
    border-radius: 14px;
}

.slider.round:before {
    border-radius: 50%;
}

@media only screen and (max-width: 400px) {
    input:checked+.slider:before {
        -webkit-transform: translateX(23px);
        -ms-transform: translateX(23px);
        transform: translateX(24px);
    }
}


.steps {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
}

.step-items {
    margin-right: 20px;
    align-items: center;
    display: flex;
    margin-bottom: 5px;
}

.step-item-number {
    color: rgba(255, 255, 255, 1);
    font-weight: 700;
    font-size: 15px;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.step-item-title {
    margin: 0px 19px;
    font-weight: 600;
    font-size: 15px;
}

@media only screen and (max-width: 576px) {
    .step-items {
        width: 100%;
    }
}