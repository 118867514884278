.applicant-profile-page {
    padding: 37px 66px;
}

.group-1 {
    padding-top: 70px;
}

.personal-info {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0px 3px 20px #00000014;
    border-radius: 28px;
    padding-top: 14px;
}

.group-1-main {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    margin-top: 10px;
}

.group-1-name {
    color: rgb(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 600;
    font-style: normal;

}

.group-1-title {
    color: rgb(0, 0, 0, 1);
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
}

.group-1-personal-qualification {
    display: flex;
    flex-direction: row;
    margin-top: 29px;
    justify-content: center;
}
.group-1-qualification {
    /* margin-left: 87px; */
    cursor: pointer
}

.group-1-personal {
    cursor: pointer;
    margin-left: 37px;
}

.group-1-qualification-details {
    /* margin-top: 30px;
    margin-left: 70px; */
    margin-top: 25px;
    padding-left: 20%;
}


.group-1-qualification-main {
    /* margin-bottom: 20px;
    display: flex;
    align-items: center; */
    display: inline-block;
    width: 100%;
}

.group-1-qualification-icon {
    width: 10%;
    float: left;
}

.group-1-qualification-title {
    width: 80%;
    float: left;
    margin-bottom: 15px;
    margin-left: 10%;
    
    /* margin-left: 20px; */
    color: rgb(0, 0, 0, 1);
    font-weight: 500;
    font-size: 15px;
    font-style: normal;
    text-transform: capitalize;
}

.group-1-personal-details {
    display: inline-block;
    width: 100%;
    margin-top: 25px;
    padding-left: 20%;
}

.group-1-personal-label {
    width: 40%;
    float: left;
}

.group-1-personal-title {
    width: 40%;
    float: left;
    color: rgb(0, 0, 0, 1);
    font-weight: 500;
    font-size: 15px;
    font-style: normal;
    text-transform: capitalize;
    margin-bottom: 15px;
}

/* group 2  */
.group-2 {
    margin-left: 119px;
}

.group-heading {
    color: rgba(0, 0, 0, 1);
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
}

.group-2-main {
    margin: 30px 0px;
    border-radius: 28px;
    box-shadow: 0px 3px 20px #00000014;
    background-color: rgba(255, 255, 255, 1);
    padding: 32px 0px;
    /* word-break: break-word; */
}

.group-2-info {
    padding-left: 46px;
}

.group-2-title {
    margin-left:  29px;
    color: rgba(0, 0, 0, 1);
    font-weight: 600;
    font-style: normal;
    font-size: 17px;
    text-transform: capitalize;
}

.group-2-education {
    margin-top: 18px;
    margin-left: 50px;
}

.group-2-body {
    color: rgba(56, 56, 56, 1);
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
}

.group-divider {
    margin-top: 20px;
}

@media only screen and (max-width: 669px) {
    .applicant-profile-page {
        padding: 37px 0px !important;
    }
}

@media only screen and (max-width: 991px ) {
    .group-2 {
        margin-left: unset !important;
        margin-top: 37px !important;
    }
}


@media only screen and (max-width: 669px) {
    .group-2-info {
        padding-left: 10px !important;
    }
}

@media only screen and (max-width: 669px) {
    .group-2-main {
        margin: 20px 0px !important;
        padding: 22px 0px !important;
    }
}

.semi-bold-black {
    color: rgba(0, 0, 0, 1);
    font-size: 13px;
    font-weight: 600;
    font-style: normal;
}

.group-subject-grade {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.group-subject {
    min-width: 15%;
}

.group-grade {
    margin-left: 15px;
}

@media only screen and (max-width: 500px) {
    .group-subject {
        min-width: 37%;
    }
}

@media  (max-width: 300px) {
    .group-1-qualification-details {
        padding-left: 10%;
    }
}

@media  (max-width: 300px) {
    .group-1-personal-details {
        padding-left: 10%;
    }

    .group-1-personal-label {
        width: 45%;
        float: left;
    }
    
    .group-1-personal-title {
        width: 45%;
        float: left;
        color: rgb(0, 0, 0, 1);
        font-weight: 500;
        font-size: 15px;
        font-style: normal;
        text-transform: capitalize;
        margin-bottom: 15px;
    }
}

@media  (max-width: 350px) {
    .group-1-status-input-submit-btn {
        padding-left: 2%;
        padding-right: 2%;
     }
}

.group-invalid-id {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: center;
    min-height: 360px;
}

.group-home-btn {
    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    width: 160px;
    height: 38px;

    font-size: 15px;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
 }

 .group-1-status-input-submit-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;
    /* padding-left: 30px; */
    padding-bottom: 80px;
 }

 .group-1-status-input {
    width: 150px;
    height: 50px;
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid;
    border-radius: 6px;
    border-color: rgba(112, 112, 112, 1);
    padding-left: 10px;
 }

 .group-1-submit-btn {
    font-size: 15px;
    font-weight: 600;
    color: rgba(255, 255, 255, 1);

    background-color: rgba(0, 140, 119, 1);
    border-radius: 7px;
    box-shadow: 0px 3px 6px #008c774b;
    border: 0.6px solid #008C77;

    width: 150px;
    height: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
 }